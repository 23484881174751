import React from 'react';
import Layout from '../components/layout';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import Seo from '../components/seo';

const Error = () => {
  return (
    <Layout>
      <Seo title="Error" />
      <main className="error-page">
        <section>
          <h1>404</h1>
          <h3>page not found</h3>
          {''}
          <hr />
          <h2>Oh Snap!</h2>
          <StaticImage
            src="../images/puppet.gif"
            alt="Error 404 page not found"
            placeholder="dominantColor"
          />
          {''}
          <hr />
          <Link to="/" align="center">&larr; Back to home</Link>
        </section>
      </main>
    </Layout>
  );
};

export default Error;
